@import '~@/styles/color.scss';

.warning-box {
  background: rgb(255, 140, 31, 0.08);
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 10px 0;
  color: rgba(26, 26, 26, 0.85);
  display: flex;
  align-items: flex-start;
  .tip-container {
    display: flex;
    flex-direction: column;
    .title {
      color: #ff8c1f;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    .tip {
      color: #1b1f4d;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      span {
        font-weight: 500;
      }
    }
  }

  img {
    width: 16px;
    margin-left: 20px;
    margin-right: 12px;
    margin-top: 3px;
  }
}

.modal-body-box {
  padding: 40px 60px 20px;
  text-align: left;
}
.box-item {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #333;
  display: flex;
  line-height: 22px;

  .item-tip {
    display: block;
    margin-left: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
    width: auto;
    flex: 1;
    color: #1b1f4d;
    
    span {
      color: #1b1f4d;
    }

    &.bold {
      font-weight: 500;
    }
    .description {
      color: #8d8fa6;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      span {
        color: #484c70;
        line-height: 18px;
      }
    }
  }
  span {
    white-space: pre-line;
    font-weight: 400;
    font-size: 14px;
    color: #8d8fa6;

    &.multiple-line {
      margin-top: 4px;
    }
    &.import {
      color: #484c70;
    }
  }
}
.line {
  margin: 20px 0;
  background-color: $divider_1;
  height: 1px;
}
.border-box-item {
  background: #fcfcfc;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px 15px;
  display: flex;
  margin-left: -15px;
  align-items: center;
  position: relative;
  margin-top: 20px;
  justify-content: space-between;
  .item-tip {
    display: block;
    font-size: 12px;
    line-height: 20px;
    margin-left: 16px;
    color: #666;
    font-weight: 400;
    flex: 1;
    span {
      color: #f2851d;
    }
  }
  &::after {
    position: absolute;
    bottom: -36px;
    width: 100%;
    height: 1px;
    content: '';
    background: #ebebeb;
    left: 0;
  }
}
.item-title {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #484c70;
}
.input-wrapper {
  background: #f7f8fa;
  padding: 12px 0;
  margin: 40px -40px -48px -40px;
  span {
    font-size: 16px;
    color: #666;
    font-weight: 400;
    flex: 1;
  }
}
.textarea {
  display: block;
  margin-left: 16px;
  height: 80px;
  border: 1px solid $frame_1;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  background: #fcfcfc;
  padding: 6px 10px;
  outline: none;
  color: #1b1f4d;
  resize: none;
  flex: 1;
  &::placeholder {
    font-weight: 400;
    color: #8d8fa6;
  }
}
.btn-group {
  display: flex;
  justify-content: center;
}
.button {
  font-size: 12px;
  border-radius: 170px;
  margin-right: 20px;
  font-weight: 500;
  box-shadow: none;
  &:last-child {
    margin-right: 0;
  }
}

.box-header {
  position: relative;
  height: auto;
  box-sizing: border-box;
  padding: 12px 20px 16px;
  display: flex;
  border-radius: 4px;
  margin-bottom: 20px;
  &.success {
    background-color: rgba(6, 194, 112, 0.08);
  }
  &.processing {
    background-color: rgba(255, 140, 31, 0.07);
  }
  &.failed {
    background-color: rgba(255, 83, 83, 0.08);
  }
  .prompt-container {
    position: absolute;
    color: #484c70;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    .course-unit {
      color: #ff5353;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      margin-right: 6px;
    }
  }
}
.header-icon {
  img {
    width: 16px;
    margin-top: 3px;
  }
}
.header-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.header-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #333;

  &.success {
    color: #06c270;
  }
  &.processing {
    color: #ff8c1f;
  }
  &.failed {
    color: #ff5353;
  }
}
.header-sub-title {
  font-size: 13px;
  color: #484c70;
  font-weight: 400;
  line-height: 20px;
  span {
    font-weight: 500;
  }
  p {
    margin-top: 4px;
  }
}
.header-tip {
  position: relative;
  max-width: 368px;
  word-break: break-word;
  font-size: 11px;
  font-weight: 400;
  color: #8d8fa6;
  margin-top: 4px;
  line-height: 18px;
  &.chinese-width {
    max-width: 420px;
  }
  &.auto {
    max-width: none;
  }
  &::before {
    position: absolute;
    content: '*';
    left: -12px;
    top: 2px;
    color: #ff5353;
    font-size: 11px;
    line-height: 14px;
  }
}
.urgent-icon {
  position: absolute;
  right: 16px;
  top: -12px;
  width: 48px;
}
.submit {
  width: 112px;
  box-sizing: border-box;
  height: 32px;
  // padding: 0;
}
.cancel {
  width: 112px;
  height: 32px;
  color: #1b1f4d;
  background-color: initial;
  border: 1px solid #dfe0ed;
}

.close {
  margin-top: 10px;
}

.m-t-20 {
  margin-top: 20px;
}
.m-b-0 {
  margin-bottom: 0;
}

.m-t-2 {
  margin-top: 4px;
}

.urgent-leave-confirm-modal {
  padding: 16px;
}

.divider {
  margin: 0 10px;
  color: #dfe0ed;
}
