@mixin mediaS {
  @media (max-width: 720px) {
    @content;
  }
}
@mixin mediaL {
  @media (min-width: 721px) {
    @content;
  }
}
@mixin mediaMaxLG {
  @media (max-width: 1023px) {
    @content;
  }
}
@mixin mediaLG {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin mediaXL {
  @media (min-width: 1600px) {
    @content;
  }
}
